import React, { useEffect, useState } from "react";
import { Circle, Image, Layer, Line, Stage, Text } from "react-konva";

interface IProps {
  imageUrl: string;
  lines: number[][];
  colors: string[];
  values: string[];
}

const CustomCanva: React.FC<IProps> = (props) => {
  const { imageUrl, lines, colors, values } = props;
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  // Load the image
  useEffect(() => {
    const img = new window.Image();
    img.crossOrigin = "anonymous"; // For cross-origin images
    img.src = imageUrl;
    img.onload = () => {
      console.log("Image loaded:", img);
      setImage(img);
    };
    img.onerror = (err) => {
      console.error("Image failed to load:", err);
    };
  }, [imageUrl]);

  // Delay rendering until the image is loaded
  if (!image) {
    return <h5>Loading...</h5>;
  }

  return (
    <Stage width={image.width} height={image.height}>
      <Layer>
        <Image image={image} width={image.width} height={image.height} listening={false} />
        {lines.map((points: any[], index: number) => {
          const [x1, y1, x2, y2] = points;

          const midX = (x1 + x2) / 2;
          const midY = (y1 + y2) / 2;

          return (
            <React.Fragment key={index}>
              <Line
                points={points}
                stroke={colors[index]} // Use the pre-generated color
                strokeWidth={2.5}
                lineJoin="round"
                lineCap="round"
              />

              <Circle x={x1} y={y1} radius={4} fill={colors[index]} />
              <Circle x={x2} y={y2} radius={4} fill={colors[index]} />

              <Text
                x={index % 2 === 0 ? midX - 55 : midX + 10}
                y={midY}
                text={values[index]}
                fontSize={14}
                fill={colors[index]}
                align="center"
                verticalAlign="middle"
              />
            </React.Fragment>
          );
        })}
      </Layer>
    </Stage>
  );
};

export default CustomCanva;