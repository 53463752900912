import React from 'react';
import styles from './styles.module.css';
import heart from '../../assets/heart.png';
const BeatingHeartIcon = () => {
    return (
        <div className={styles['heart-container']}>
            <div className={`${styles['heart']} ${styles['heart-realistic']}`}>

                <img src={heart} alt="Beating Heart" width={100} height={100}/>
            </div>

        </div>
        
    );
};

export default BeatingHeartIcon;