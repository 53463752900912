import axios from "axios";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ApiApi, Configuration } from "./generated_api";
import { axiosClient } from "./api";


const SetupInterceptors = (navigate: NavigateFunction) => {
  axiosClient.interceptors.request.use(
    (config) => {
      if (config !== undefined && config.headers !== undefined) {
        const token = localStorage.getItem("token");
        if (token !== null && token !== undefined)
          config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const res = error.response;
      if (res.status === 401) {
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );
};
const configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
});
const apiClient = new ApiApi(configuration, process.env.REACT_APP_API_URL, axiosClient);
export { apiClient, SetupInterceptors };
