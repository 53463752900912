import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgressProps,
  Box,
  Grid,
  Alert,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/material/LinearProgress";
import { Preloader } from "../../components/preloader";
import { DiameterMeasurementRow, DicomRow, RegressionMeasurementRow } from "./tableRow";
import styles from "./reportDetails.module.css";
import { apiClient } from "../../api/base";
import { PathologyRow } from "./pathologyRow";
import {
  DiameterConclusion,
  DiameterPrediction,
  PrivateOpenAPIReport,
  ReportConclussion,
  ReportDicom,
} from "../../api/generated_api";
import { DiameterVisualizer } from "./DiameterVisualizer";
import { SegmentationRow } from "./segmentationRow";
import GroupedDiameterMeasurementRow from "./components/GroupedDiameterMeasurementRow";

export const ReportDetails = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showGroupedMeasures, setShowGroupedMeasures] = useState<boolean>(false);

  const [reportData, setReportData] = useState<PrivateOpenAPIReport | undefined>(undefined);

  useEffect(() => {
    id &&
      apiClient
        .retrievePrivateOpenAPICompatibleReport({ uuid: id })
        .then((res) => {
          setReportData(res?.data);
        })
        .catch((err) => {
          enqueueSnackbar("Reports failed to load", { variant: "error" });
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, []);

  const conclusions_sort_function = (a: ReportConclussion, b: ReportConclussion) => {
    return a.order - b.order;
  };

  const display_conclusions = (conclusions: ReportConclussion[] | undefined) => {
    if (conclusions == undefined) {
      return;
    }
    return conclusions.sort(conclusions_sort_function).map((conclusion) => {
      return (
        <p key={conclusion.text} className={styles.conclusion}>
          {conclusion.order}. {conclusion.text}
        </p>
      );
    });
  };

  const diameter_visualization = (conclusion: DiameterConclusion) => {
    let dicom_object: ReportDicom | undefined;
    if (reportData?.dicoms !== undefined) {
      if (reportData.dicoms.length > 0)
        dicom_object = reportData.dicoms.find((dicom) => dicom.uuid === conclusion.dicoms[0].uuid);
    }

    return dicom_object && <DiameterVisualizer dicom={dicom_object} diameters={conclusion.diameters ?? []} key={"1"} />;
  };

  const handleShowGroupedChange = () => {
    setShowGroupedMeasures((prevState) => !prevState);
  };

  // const [measurements, setMeasurements] = useState<any[]>([]);
  // const [dicoms, setDicoms] = useState<any[]>([]);
  //
  // useEffect(() => {
  //   const fetchMeasurements = async () => {
  //     try {
  //       const response = await fetch("/reports.json");
  //       const data = await response.json();
  //       setMeasurements(data.diameter_measurements);
  //       setDicoms(data.dicoms);
  //     } catch (error) {
  //       console.error("Error fetching measurements:", error);
  //     }
  //   };
  //
  //   fetchMeasurements();
  // }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Card className={styles.studyCard}>
            <CardContent>
              <Typography variant="h5" component="div">
                Study: {reportData?.study?.name}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Created at:{" "}
                {reportData?.study?.created_at && new Date(reportData?.study?.created_at)?.toLocaleString("en-GB")}
              </Typography>
              <Typography variant={"h5"} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Conclusions:
                {display_conclusions(reportData?.enumerated_conclusions)}
              </Typography>
              <Typography variant={"h5"} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                System version: {reportData?.version}
              </Typography>
            </CardContent>
            <CardActions className={styles.downloadButtonWrapper}>
              <Button size="small">
                <a
                  download={reportData?.study?.name && reportData?.study.name + ".pdf"}
                  href={reportData?.file}
                  target="_blanc"
                  className={styles.downloadPDFLink}
                >
                  Download as PDF
                </a>
              </Button>
            </CardActions>
            <>
              {reportData?.warnings?.low_quality.map((error, i) => {
                return (
                  <Alert key={error.dicom.uuid} severity="error">
                    {error.message}
                  </Alert>
                );
              })}
              {reportData?.warnings?.viewport_not_found?.map((error) => {
                return (
                  <Alert key={error.viewport.view} severity="error">
                    {error.message}
                  </Alert>
                );
              })}
              {reportData?.warnings?.diameter_outside_range?.map((error) => {
                return <Alert severity="error">{error.message}</Alert>;
              })}
              {reportData?.warnings?.other?.map((error) => {
                return <Alert severity="error">{error.message}</Alert>;
              })}
            </>
          </Card>

          <TableContainer component={Paper} className={styles.measurementsTable}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableCell}></TableCell>
                  <TableCell className={styles.tableCell}>Dicom name</TableCell>
                  <TableCell className={styles.tableCell}>Viewport</TableCell>
                  <TableCell align="right" className={styles.tableCell} colSpan={2}>
                    Quality
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData?.dicoms?.map((dicom) => (
                  <DicomRow data={dicom} key={dicom.uuid} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {reportData?.pathology_conclusions && reportData.pathology_conclusions.length > 0 && (
            <TableContainer component={Paper} className={styles.pathologiesTable}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableCell}></TableCell>
                    <TableCell className={styles.tableCell}>Feature</TableCell>
                    <TableCell className={styles.tableCell}>Pathology</TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      Prediction
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.pathology_conclusions?.map(
                    (data, i) => reportData?.dicoms && <PathologyRow key={i} data={data} dicoms={reportData?.dicoms} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <TableContainer component={Paper} className={styles.measurementsTable}>
            <FormControlLabel
              control={<Switch checked={showGroupedMeasures} onChange={handleShowGroupedChange} />}
              label="Show grouped measurements"
            />
            {showGroupedMeasures ? (
              <>
                <GroupedDiameterMeasurementRow measurements={reportData?.diameter_measurements as DiameterConclusion[]} dicoms={reportData?.dicoms} />
                {/*<GroupedDiameterMeasurementRow measurements={measurements as DiameterConclusion[]} dicoms={dicoms} />*/}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {!showGroupedMeasures && (
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.tableCell}></TableCell>
                        <TableCell className={styles.tableCell}>Measurements</TableCell>
                        <TableCell className={styles.tableCell}>Flag</TableCell>
                        <TableCell className={styles.tableCell}>Value</TableCell>
                        <TableCell align="right" className={styles.tableCell} colSpan={2}>
                          Reference
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {reportData?.regression_measurements?.map((row, i) => (
                      <RegressionMeasurementRow data={row} key={row.measurement.key} />
                    ))}

                    {reportData?.segmentation_measurements?.map((row, i) => {
                      return <SegmentationRow key={`segmentation ${i}`} data={row} dicoms={reportData?.dicoms} />;
                    })}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableCell}></TableCell>
                    <TableCell className={styles.tableCell}>Measurements</TableCell>
                    <TableCell className={styles.tableCell}>Flag</TableCell>
                    <TableCell className={styles.tableCell}>Value</TableCell>
                    <TableCell align="right" className={styles.tableCell} colSpan={2}>
                      Reference
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.regression_measurements?.map((row, i) => (
                    <RegressionMeasurementRow data={row} key={row.measurement.key} />
                  ))}

                  {reportData?.diameter_measurements?.map((row) => (
                    <DiameterMeasurementRow data={row} key={row.measurement.key} dicoms={reportData?.dicoms} />
                  ))}

                  {reportData?.segmentation_measurements?.map((row, i) => {
                    return <SegmentationRow key={`segmentation ${i}`} data={row} dicoms={reportData?.dicoms} />;
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};
