import React, { useEffect, useRef, useState } from "react";

import { DiameterConclusion, DiameterConclusionFlagEnum, ReportDicom } from "../../../../api/generated_api";

import { Group } from "react-konva";
import MeasurementGroup from "../MeasurementGroup";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import styles from "../../reportDetails.module.css";
import { DiameterMeasurementRow, RegressionMeasurementRow } from "../../tableRow";
import { SegmentationRow } from "../../segmentationRow";

type MeasurementData = {
  dicoms: { uuid: string }[];
  measurement: any;
  color: any;
  value: number;
  diameters: {
    dicom: string;
    frame: {
      index: number;
      file: string;
      delta_x: number;
      delta_y: number;
    };
    keypoint_predictions: { coordinate_x: number; coordinate_y: number }[];
    visualization: string;
  }[];
  flag: string | null;
};

type GroupedResult = {
  [key: string]: DiameterConclusion[];
  ungrouped: DiameterConclusion[];
};

function createFileFromPath(path: string): File {
  const blob = new Blob([], { type: "application/octet-stream" });
  return new File([blob], path.split("/").pop() || "unknown", { type: "application/octet-stream" });
}

function convertToDiameterConclusion(data: MeasurementData): DiameterConclusion {
  return {
    dicoms: data.dicoms.map((d) => ({ uuid: d.uuid })),
    measurement: data.measurement, // Assuming measurement is already in the correct format
    value: data.value || null,
    diameters: data.diameters.map((d) => ({
      dicom: d.dicom,
      frame: {
        index: d.frame.index,
        file: d.frame.file,
        delta_x: d.frame.delta_x,
        delta_y: d.frame.delta_y,
      },
      keypoint_predictions: d.keypoint_predictions.map((kp) => ({
        coordinate_x: kp.coordinate_x,
        coordinate_y: kp.coordinate_y,
      })),
      visualization: d.visualization ? createFileFromPath(d.visualization) : null,
    })),
    flag: data.flag as DiameterConclusionFlagEnum,
  };
}

interface IProps {
  measurements: DiameterConclusion[];
  dicoms?: ReportDicom[];
}

const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // Convert to hex
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const generateLightColorsHex = (): string => {
  const hue = Math.random() * 360; // Full hue range (0°–360° for variety)
  const saturation = 60 + Math.random() * 20; // Saturation: 60%–80% (pastel range)
  const lightness = 70 + Math.random() * 20; // Lightness: 70%–90% (bright and clear)

  return hslToHex(hue, saturation, lightness);
};

const GroupedDiameterMeasurementRow: React.FC<IProps> = (props) => {
  const { measurements, dicoms } = props;
  const [groupedData, setGroupedData] = React.useState<GroupedResult>();

  // const groupByDiameters = (data: DiameterConclusion[]): GroupedResult => {
  //   const groupedResult: GroupedResult = { ungrouped: [] };
  //   const tempGroups: { [key: string]: DiameterConclusion[] } = {};
  //
  //   // First pass to group by diameters
  //   data.forEach((item) => {
  //     const shouldBeUngrouped =
  //       item.measurement.value.includes("Systolic") || item.measurement.value.includes("Diastolic");
  //
  //     if (!shouldBeUngrouped && item.diameters) {
  //       item.diameters.forEach((diameter) => {
  //         if (diameter.frame && diameter.frame.file && diameter.dicom) {
  //           const groupKey = `${diameter.frame.file}_${diameter.dicom}`;
  //           if (!tempGroups[groupKey]) {
  //             tempGroups[groupKey] = [];
  //           }
  //           (item as any).color = generateLightColorsHex(); // Dynamically add a color property
  //           tempGroups[groupKey].push(item);
  //         } else {
  //           groupedResult.ungrouped.push(item); // If frame or required properties are missing, add to ungrouped
  //         }
  //       });
  //     }
  //
  //     if (shouldBeUngrouped || !item.diameters || item.diameters.length === 0) {
  //       groupedResult.ungrouped.push(item);
  //     }
  //   });
  //
  //   // Second pass to identify groups with a single item
  //   Object.entries(tempGroups).forEach(([groupKey, items]) => {
  //     if (items.length === 1) {
  //       groupedResult.ungrouped.push(...items);
  //     } else {
  //       groupedResult[groupKey] = items;
  //     }
  //   });
  //
  //   return groupedResult;
  // };

  const groupByDiameters = (data: DiameterConclusion[]): GroupedResult => {
    const groupedResult: GroupedResult = { ungrouped: [] };
    const tempGroups: { [key: string]: DiameterConclusion[] } = {};

    // First pass to group by diameters
    data.forEach((item) => {
      if (item.diameters) {
        item.diameters.forEach((diameter) => {
          if (diameter.frame && diameter.frame.file && diameter.dicom) {
            const groupKey = `${diameter.frame.file}_${diameter.dicom}`;
            if (!tempGroups[groupKey]) {
              tempGroups[groupKey] = [];
            }
            (item as any).color = generateLightColorsHex(); // Dynamically add a color property
            tempGroups[groupKey].push(item);
          } else {
            groupedResult.ungrouped.push(item); // If frame or required properties are missing, add to ungrouped
          }
        });
      } else {
        groupedResult.ungrouped.push(item); // Items with no diameters are ungrouped
      }
    });

    // Second pass to identify groups with a single item
    Object.entries(tempGroups).forEach(([groupKey, items]) => {
      if (items.length === 1) {
        groupedResult.ungrouped.push(...items);
      } else {
        groupedResult[groupKey] = items;
      }
    });

    return groupedResult;
  };

  useEffect(() => {
    const grouped = groupByDiameters(measurements);
    setGroupedData(grouped);
  }, [measurements]);

  if (!groupedData) return <p>Loading...</p>;

  const { ungrouped, ...groups } = groupedData;

  return (
    <>
      {Object.entries(groups).map(([groupKey, items], index) => (
        <MeasurementGroup groupKey={groupKey} index={index} items={items} dicoms={dicoms} />
      ))}

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableCell}></TableCell>
            <TableCell className={styles.tableCell}>Measurements</TableCell>
            <TableCell className={styles.tableCell}>Flag</TableCell>
            <TableCell className={styles.tableCell}>Value</TableCell>
            <TableCell align="right" className={styles.tableCell} colSpan={2}>
              Reference
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ungrouped?.map((row) => (
            <DiameterMeasurementRow data={row} key={row.measurement.key} dicoms={row.dicoms as ReportDicom[]} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default GroupedDiameterMeasurementRow;
