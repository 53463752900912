import React, { useState } from "react";
import styles from "../GroupedDiameterMeasurementRow/groupedDiameterMeasurementRow.module.css";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { formatMeasurementValue } from "../../utils";
import { ReportDicom } from "../../../../api/generated_api";
import CustomCanva from "../CustomCanva";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface IProps {
  groupKey: string;
  index: number;
  items: any;
  dicoms?: ReportDicom[];
}

const MeasurementGroup: React.FC<IProps> = (props) => {
  const { groupKey, items, index, dicoms } = props;
  const [open, setOpen] = useState<boolean>(false);

  const infoGroup = (items: any[]) => {
    return (
      items.length > 1 && (
        <div className={styles.parentInfoWrapper}>
          {items.map((item) => (
            <div className={styles.groupedInfoWrapper}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={styles.modelDot} style={{ backgroundColor: item.color }}></div>
                    <Typography align={"left"} fontWeight={"bold"}>
                      {item.measurement.value}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Typography align={"left"}>
                    <strong>Flag: </strong>
                    {item.flag}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align={"left"}>
                    <strong>Value: </strong>
                    {formatMeasurementValue(item.value)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <strong>Reference: </strong>
                  {item?.measurement.low_range !== undefined
                    ? `${item?.measurement.low_range} - ${item?.measurement.high_range} ${item?.measurement.units}`
                    : "-"}
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )
    );
  };

  const frameGroup = (items: any[]) => {
    let dicomObject: ReportDicom | undefined;
    const lines: any[] = [];
    const colors: string[] = [];
    const values: string[] = [];

    items.map((item) => {
      const line: number[] = [];
      const keyPoints = item.diameters[0].keypoint_predictions?.map((keypoint: any) => {
        line.push(keypoint?.coordinate_x);
        line.push(keypoint?.coordinate_y);
      });
      line.concat(keyPoints);
      lines.push(line);
      colors.push(item?.color);
      values.push(`${formatMeasurementValue(item.value)} ${item.measurement.units}`);
    });

    if (dicoms !== undefined) {
      if (dicoms.length > 0) dicomObject = dicoms.find((dicom) => dicom.uuid === items[0].dicoms[0].uuid);
    } else return <p>Loading...</p>;

    return (
      <div className={styles.canvaWrapper}>
        <Typography align={"center"} style={{marginBottom: 16}} fontWeight={"bold"}>{dicomObject && dicomObject.name}</Typography>
        <CustomCanva imageUrl={items[0].diameters[0].frame.file} lines={lines} colors={colors} values={values} />
      </div>
    );
  };

  return (
    <div key={groupKey}>
      <h5>
        Group {index + 1}
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen((prevState) => !prevState)} style={{marginLeft: 10}}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </h5>
      <>{infoGroup(items)}</>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography variant="h6" gutterBottom component="div">
            {frameGroup(items)}
          </Typography>
        </Box>
      </Collapse>
    </div>
  );
};

export default MeasurementGroup;