import {
  Button,
  Drawer,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import { FC, useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import EchoGPTReport from "./components/EchoGPTReport";
import React from "react";
import { useParams } from "react-router-dom";
import BeatingHeartIcon from "src/components/BeatingHeart";

interface IThumbnailProps {
  showCaption?: boolean;
  caption?: string;
  uuid: string;
  filename: string;
  signed_media_url: string;
  signed_frame_url: string;
}

const Thumbnail: FC<IThumbnailProps> = (props) => {
  const { showCaption, caption, uuid, filename, signed_media_url, signed_frame_url } = props;
  const [dicomSignedUrl, setDicomSignedUrl] = useState<string | null>(null);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    const fetchDicomSignedUrl = async () => {

      // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/dicoms/file/002409d5-837a-4ac0-9daf-3d04d660baad/PLAX_example.dcm/`, {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application",
      //     "Authorization": `Bearer ${localStorage.getItem("token")}`,
      //   },
      // });

      // const data = await response.json();
      // setDicomSignedUrl(data);
    };

    fetchDicomSignedUrl();
  }, [uuid, filename]);


  // useEffect(() => {
  //     const img = new window.Image();
  //     img.crossOrigin = "anonymous"; // For cross-origin images
  //     img.src = dicomSignedUrl as string;
  //     img.onload = () => {
  //       console.log("Image loaded:", img);
  //       setImage(img);
  //     };
  //     img.onerror = (err) => {
  //       console.error("Image failed to load:", err);
  //     };
  //   }, [dicomSignedUrl]);

  
  return (
    <div className={styles.thumbnailWrapper}>
      {showCaption && (
        <Typography component="div" className={styles.caption}>
          {caption}
        </Typography>
      )}

      <>
      {
        signed_frame_url && !signed_media_url && (
          <img
            alt=""
            src={`${(signed_frame_url as string)}`}
          />
        )
      }
      {signed_media_url && (
        <video 
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
            width: "auto",
            height: "auto"
          }}
          controls
          src={`${(signed_media_url as string)}`}
        />
      )}


  {/* <iframe
    src={dicomSignedUrl as string}
    
  ></iframe> */}

      </>

      



    </div>
    
  );
};

export type ItemReport = {
  uuid: string;
  file: string;
  view: string;
  conclusion: string;
};

const EchoGPT = () => {
  const [response, setResponse] = useState<Record<string, any> | null>(null);
  const [data, setData] = useState<ItemReport[]>([]);
  const [open, setOpen] = React.useState(false);
  const { id: slug } = useParams();

  useEffect(() => {
    const fetchEchogpt = async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/api/v2/echogpt/report/?use_stub=true`;
        if (slug) {
          url = `${process.env.REACT_APP_API_URL}/api/v2/echogpt/report/${slug}/`;
        }
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('data', data)
        setResponse(data.response);
        // setData(data[0].view_file_pairs);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately
      }
    }

    fetchEchogpt();
  }, [slug]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };



  if (!response) {
    return <div className={styles.loadingWrapper}>
    <BeatingHeartIcon />
    <div>Loading...</div>
    </div>
  }

  return (
    <>
    {/* TODO: Add back in */}
      {/* <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: { width: "45%", marginTop: "64px", zIndex: 1000 },
        }}
      >
        <PDFViewer width="100%" height={"100%"}>
          <EchoGPTReport data={data}/>
        </PDFViewer>
      </Drawer> */}

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {
              response?.['view_file_pairs'].map((item: Record<string, any>, index: number) => (
                <Grid item xs={4} key={index}>
                  <Thumbnail showCaption caption={item.view}  uuid={item['dicom_uuid']} filename={item['filename']} signed_media_url={item['signed_media_url']} signed_frame_url={item['signed_frame_url']}/>
                </Grid>
              ))
            }
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <div className={styles.pdfReportHeaderWrapper}>
            <Typography fontWeight={"bold"} component="div" className={styles.pageName}>
              EchoGPT Interpretations
            </Typography>

            {/* <Button onClick={toggleDrawer(true)}>Open Report</Button> */}
          </div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>View</TableCell>
                  <TableCell>Conclusion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
              response?.['report_sections'].map((item: any, index: number) => (
                  <TableRow key={`${item.view}_${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {item.view}
                    </TableCell>
                    <TableCell>{item.conclusion.split(':')[1]}</TableCell>
                  </TableRow>
                ))
              }

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default EchoGPT;
